import React, { FC, memo } from 'react'
import { PageProps } from 'gatsby'

import LayoutRegistry from '@components/layoutRegistry'
import { getLastPathElem } from '@utils/utils'

import { IListByCity, ICity } from 'types/agency'
import { catalogAPI } from '@api/catalog'
import { headers } from '@utils/constants'

interface IServerDataType {
  cities: ICity[]
  agenciesList: IListByCity[]
}

const AgencyByCity: FC<PageProps<object, object, unknown, IServerDataType>> = ({
  location: { pathname },
  serverData: { cities, agenciesList },
}) => (
  <LayoutRegistry
    data={{
      cities,
      activeData: agenciesList,
      type: 'agency',
      cityIdPath: getLastPathElem(pathname),
    }}
  />
)

export async function getServerData() {
  try {
    const cities = (await catalogAPI.getCities(false, true)) || []

    let agenciesList: IListByCity[] = []
    let citiesList: ICity[] = []

    if (cities?.length) {
      for (const city of cities) {
        const agency = await catalogAPI.getAgencies(false, getLastPathElem(city['@id']))

        if (agency?.data?.length) {
          agenciesList.push({ list: agency, cityId: +getLastPathElem(city['@id']) })
          citiesList.push(city)
        }
      }
    }

    return {
      props: {
        cities: citiesList,
        agenciesList,
      },
      headers,
    }
  } catch (e) {
    return {
      props: {
        cities: [],
        agenciesList: [],
      },
      headers,
    }
  }
}

export default memo(AgencyByCity)
